@import 'src/Utils/variables.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $darkBlue;
}

.modal {
  border: none;
  border-radius: 4px;
  padding: 2rem;
  width: 'auto';

  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  @include L-C;
  padding-bottom: 2rem;
}
