@import 'src/Utils/variables.scss';

$loop_time: 1.2s;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $lightBlue;

  div {
    background-color: white;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay $loop_time infinite ease-in-out both;
    animation: sk-bouncedelay $loop_time infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -$loop_time * calc(2 / 7);
    animation-delay: -$loop_time * calc(2 / 7);
  }
  .bounce2 {
    -webkit-animation-delay: -$loop_time * calc(1/ 7);
    animation-delay: -$loop_time * calc(1 / 7);
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  40%,
  100% {
    -webkit-transform: scale(0.7);
  }
  20% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  60%,
  100% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  30% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
