@import 'src/Utils/variables.scss';

.container {
  width: 18.75rem;
  display: flex;
  flex-direction: column;
}

.titleBar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.125rem;
  margin-bottom: 2rem;
}

.separator {
  @extend %horizontal-line;
}

.dateRangePicker {
  padding-bottom: 2rem;
}

.title {
  @include XL-L;
  color: black;
}

.subtitle {
  @include L-L;
  color: black;
}

.reinit {
  TextButton {
    height: 1.25rem;
    padding: 0;
  }
}
