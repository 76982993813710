@import 'src/Utils/variables.scss';

.style {
  @extend %button-border;
  background-color: transparent;
  border-color: $lightBlue;

  width: 100%;
  height: 100%;
  flex-direction: column;

  :hover {
    cursor: pointer;
  }
}

.styleWhite {
  border-color: white;
}

.disabled {
  border-color: $brownGrey;
}

.titleStyle {
  @include M-C;
  color: $lightBlue;
}

.titleStyleWhite {
  @include M-C;
  color: white;
}

.textDisabled {
  color: $brownGrey;
}
