@import 'src/Utils/variables.scss';

.container {
  width: 50rem;
  height: 90vh;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.topContainer {
  width: 50rem;
  height: 9rem;
  background-color: $darkBlue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.topRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
}

.topRowIcon {
  cursor: pointer;
}

.separator {
  @extend %horizontal-line;
}

.arrowButtons {
  height: 2.5rem;
  cursor: pointer;
}

.icon {
  cursor: pointer;
}

.modalTitle {
  @include L-L;
  color: white;
}

.bottomContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  max-height: calc(90vh - 9rem);
}

.leftColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-left: 1rem;
  overflow: auto;
}

.title {
  @include XL-L;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.detailSpacing {
  height: 1rem;
}

.descriptionTitle {
  @include L-L;
  padding-top: 1.5rem;
}

.description {
  @include L-L;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
}

.disabledArrow {
  filter: opacity(30%);
  cursor: default;
}

.disabledText {
  filter: opacity(30%);
}

.sameClientOffer {
  margin-bottom: 1rem;
}

.sameClientOffers {
  margin-bottom: 1.5rem;
}

.noSameClientOffers {
  color: $brownGrey;
  font-style: italic;
}
