@import 'src/Utils/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  align-items: center;
}

.pageNumber {
  @include M-L;
  color: $lightBlue;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected {
  color: $white;
  background-color: $lightBlue;
  cursor: default;
}

.arrow {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}

.rightArrow {
  transform: rotate(90deg);
}

.leftArrow {
  transform: rotate(-90deg);
}

.disabledArrow {
  cursor: default;
  filter: grayscale(100%) opacity(50%);
}
