@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

$lightBlue: #2175d9;
$darkBlue: #0f1941;
$brownGrey: #979797;
$brownGreyTwo: #8a8a8a;
$white: #ffffff;
$offWhite: #f7f5f0;
$veryLightPink: #d7d7d7;
$lightBlueGrey: #c5dbe1;
$greyBlue: #6ba5b4;
$powderBlue: #cdd6e0;
$paleGrey: #edf0f5;
$black: #000000;
$tomato: #e74536;
$green: #3ba817;
$tealish: #21c0d9;
$red: #cd0000;
$tangerine: #fb8c17;
$lightGrey: #f7f5f0;
$tomato: #e74536;
$white20: rgba(255, 255, 255, 0.2);
$white60: rgba(255, 255, 255, 0.6);
$black20: rgba(0, 0, 0, 0.2);

@font-face {
  font-family: Graphik;
  src: local(Graphik-Regular), url(./../Assets/Graphik-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@mixin XXL-L {
  font-family: Graphik;
  font-size: 1.75rem;
  line-height: 1.29;
  letter-spacing: -0.5px;
  text-align: left;
}

@mixin XL-L {
  font-family: Graphik;
  font-size: 1.563rem;
  line-height: 1.28;
  letter-spacing: -0.5px;
  text-align: left;
}

@mixin XL-C {
  font-family: Graphik;
  font-size: 1.563rem;
  line-height: 1.28;
  letter-spacing: -0.5px;
  text-align: center;
}

@mixin L-L {
  font-family: Graphik;
  font-size: 1.125rem;
  line-height: 1.33;
  text-align: left;
}

@mixin L-C {
  font-family: Graphik;
  font-size: 1.125rem;
  line-height: 1.33;
  text-align: center;
}

@mixin M-L {
  font-family: Graphik;
  font-size: 1rem;
  line-height: 1.25;
  text-align: left;
}

@mixin M-R {
  font-family: Graphik;
  font-size: 1rem;
  line-height: 1.25;
  text-align: right;
}

@mixin M-C {
  font-family: Graphik;
  font-size: 1rem;
  line-height: 1.25;
  text-align: center;
}

@mixin S-L {
  font-family: Graphik;
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
}

@mixin S-C {
  font-family: Graphik;
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: center;
}

@mixin XS-C {
  font-family: Graphik;
  font-size: 0.625rem;
  line-height: 1.4;
  text-align: center;
}

@mixin XS-L {
  font-family: Graphik;
  font-size: 0.625rem;
  line-height: 1.4;
  text-align: left;
}

%horizontal-line {
  height: 1px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-color: $veryLightPink;
  content: ' ';
  display: block;
}

%button-border {
  border-width: 0.1rem;
  border-radius: 0.25rem;
  border-style: solid;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: $lightBlue;
}

%one-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%separator {
  height: 0.063rem;
  background-color: $veryLightPink;
}

@mixin icon-button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  :hover {
    cursor: pointer;
  }
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
  }
}
