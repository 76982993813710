@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding-top: 1.5rem;
}

.cardItem {
  padding-left: 0;
  margin-bottom: 1rem;
}

.errorText {
  @include Mnavyleft;
  margin-top: 2rem;
}

.title {
  @include Sblueleft;
}

.noResult {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noResultImage {
  height: 12rem;
}
