@import 'src/Utils/variables.scss';
@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.fullContainer {
  background-color: $lightBlue;
  background-image: url('../../../Assets/FlechesPige.svg');
  background-repeat: no-repeat;
  background-position: 90% 20%;
}
.container {
  display: flex;
  flex-direction: column;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 5rem;
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 5rem;
  padding-left: 3.75rem;
  padding-right: 3.75rem;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: $lightBlue;
  z-index: 1;
}

.bottomContainer {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(14.5rem, 20.5rem)) minmax(13rem, 17rem) 3.5rem;
  padding-left: 1.25rem;
}

.link {
  padding-left: 1.25rem;
  margin-bottom: 1.25rem;
}

.citySearchContainer {
  grid-column: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 0.125rem;
  max-width: 20.5rem;
}

.clientSearchContainer {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 0.125rem;
  max-width: 20.5rem;
}

.nafSearchContainer {
  grid-column: 3;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 0.125rem;
  max-width: 20.5rem;
}

.buttonContainer {
  grid-column: 4;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 0.125rem;
  max-width: 20.5rem;
}

.searchTitle {
  @include M-L;
  color: white;
  margin-bottom: 0.5rem;
  height: 1.25rem;
}

.appLogo {
  height: 7rem;
  margin-top: -1rem;
  padding-top: 1.25rem;
}

.separator {
  width: 1px;
  height: 70%;
  background-color: $veryLightPink;
  margin: 0 1rem;
  margin-top: 1.25rem;
}

.horizontalSeparator {
  @extend %horizontal-line;
}

.title {
  @include XXL-L;
  color: white;
  margin: 0;
  padding: 0;
  justify-self: flex-end;
  line-height: 3.2rem;
  padding-top: 1.25rem;

  :first-child {
    margin-right: 0.625rem;
  }
}

.topButtons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:first-of-type {
    > div:first-of-type {
      height: 5rem !important;
      padding-top: 1.25rem;
    }
  }
}

.whiteColor {
  min-width: 2rem;
  max-width: 2.25rem;
  min-height: 2rem;
  max-height: 2.25rem;
  padding: 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  margin-right: 1rem;
  color: white;
  opacity: 0.5;
}

.whiteColor:hover {
  opacity: 1;
}

.selectedIcon {
  opacity: 1 !important;
}

.bottomBar {
  background-color: white !important;
}

.segmentedMenuText {
  @include Mwhitelightleft;
  color: white !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
}

.icon {
  max-width: 1.5rem;
  max-height: 1.5rem;
  opacity: 0.6;
  path {
    fill: white;
  }
}

.heartIcon {

  max-width: 2rem;
  max-height: 2rem;
  opacity: 0.6;

  path {
    fill-opacity: 1;
  }
}

.favContainer {
  display: flex;
  flex-direction: row;
}

.favText {
  color: $main-blue-light;
  margin-top: 0.72rem;
  cursor: pointer;
}

.triggerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
  margin-top: 0.5rem;
  margin-left: 0.75rem;
  margin-bottom: 2.125rem;
  cursor: pointer;
  width: fit-content;
}

.triggerText {
  color: $main-blue-light;
}

.verticalSeparator {
  background-color: $main-blue-light;
  width: 1px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.75rem;
  height: 0.9rem;
}
