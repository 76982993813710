@import 'src/Utils/variables.scss';

.errorContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1;
  background-color: $tomato;
  justify-content: center;
  position: relative;
}

.errorTextWhite {
  @include XXL-L;
  color: white;
  position: absolute;
  top: 17rem;
  left: 2rem;
}

.errorTextDark {
  @include XXL-L;
  color: $darkBlue;
  position: absolute;
  top: 20rem;
  left: 2rem;
  width: 30rem;
}

.retryButton {
  height: 4rem;
  width: 20rem;
  justify-self: self-end;
  margin-bottom: 2rem;
}

.errorImage {
  position: absolute;
  top: 2rem;
  left: 28rem;
}
