@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  max-width: 20.5rem;
}

.autosuggest {
  border-color: white;
}
.autosuggest > div > div > fieldset {
  border-color: transparent;
}

.autosuggest > div > div {
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: inherit;
}

.autosuggest > div {
  background-color: transparent;
}

.autosuggest * {
  color: white;
}

.textInput {
  background-color: transparent !important;
  color: white;
}

.chip {
  background-color: white !important;
}

.chip > span {
  color: $main-blue-full !important;
}

.chip > svg {
  fill: $main-blue-full;
}
