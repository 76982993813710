.hiddenButton {
  position: relative;
  top: -5rem;
  width: 18.75rem;
  height: 3.5rem;
  z-index: -1;
}

.container {
  background-color: white;
}
