@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0;
}
