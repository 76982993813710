@import 'src/Utils/variables.scss';

.container {
  width: 31.25rem;
  height: 36rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
}

.goBackContainer {
  height: 3rem;
  width: 13rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.goBackArrow {
  transform: rotate(90deg);
}

.text {
  color: $lightBlue;
}

.separator {
  height: 1px;
  background-color: $veryLightPink;
}

.dateInputs {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

.dateColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dateInput {
  @include M-L;
  width: 10rem;
  height: 3.75rem;
  border-radius: 0.125rem;
  border: solid 0.5px $veryLightPink;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
}

.subtitle {
  @include S-L;
  color: $brownGreyTwo;
  margin-bottom: 0.5rem;
}

.picker {
  flex-grow: 1;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 3.75rem;
  justify-content: space-evenly;
}

.button {
  width: 9.375rem;
  height: 3.75rem;
}
