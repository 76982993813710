@import 'src/Utils/variables.scss';

.value {
  @include M-L;
  color: $darkBlue;
  word-break: break-word;
}

.emptyMessage {
  color: $brownGrey;
  font-style: italic;
}
