@import 'src/Utils/variables.scss';

.container {
  height: 3.5rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.dropDownContainer {
  height: 25rem;
  overflow-y: scroll;
}

.dropdown {
  color: white;
  width: 100%;
  svg {
    path {
      fill: white;
    }
  }
  > div {
    text-overflow: ellipsis;
    min-width: calc(100% - 1.5rem);
    max-width: calc(100% - 1.5rem);
  }
}
