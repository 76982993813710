@import 'src/Utils/variables.scss';

.style {
  @extend %button-border;
  background-color: $lightBlue;
  width: 100%;
  height: 100%;
  flex-direction: column;
  :hover {
    cursor: pointer;
  }
}

.disabled {
  border-color: $brownGrey;
  background-color: $brownGrey;
}

.titleStyle {
  @include M-C;
  color: $white;
}
