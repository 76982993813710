@import 'src/Utils/variables.scss';

.style {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.disabledStyle {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
}

.titleStyle {
  @include M-C;
  color: $lightBlue;
  margin: 0;
}

.titleDisabledStyle {
  @include M-C;
  color: $brownGrey;
}
