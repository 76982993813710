@import 'src/Utils/variables.scss';

.container {
  @extend %button-border;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0px 4px 4px 0px;
  background-color: white;
  border-color: white;
  cursor: pointer;
}
