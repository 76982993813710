@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
@import 'src/Utils/variables.scss';

.annotation {
  margin-bottom: 1rem;
}

.list {
  list-style-type: none;
}

.text {
  width: 100%;
}

.textAreaLabel {
  height: 1.33rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textAreaLeftLabel {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $brownGreyTwo;
  font-family: Graphik;
}

.textAreaRightLabel {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: $main-navy-full;
  font-family: Graphik;
}

.icon {
  margin-top: -1.5rem;
}
