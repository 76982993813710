@import 'src/Utils/variables.scss';

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  width: '100%';
  height: 5.625rem;
}
