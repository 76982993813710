@import '~react-toastify/dist/ReactToastify.css';
@import 'src/Utils/variables.scss';

.Toastify__toast--success {
  background-color: $green;
}

.Toastify__toast--success {
  background-color: $green;
}

.Toastify__toast--dark {
  background-color: $darkBlue;
}

.Toastify__toast--error {
  background-color: $red;
}

.Toastify__toast {
  border-radius: 0.125rem;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.Toastify__toast-body {
  text-align: left;
}
