@import 'src/Utils/variables.scss';

.container {
  @extend %flex-center;
  width: 100%;
  height: 100%;

  background-color: $darkBlue;
}

.modal {
  border: none;
  border-radius: 0.5rem;
  width: 21.125rem;

  background-color: white;

  @extend %flex-center;
  flex-direction: column;
  position: relative;
  padding-top: 9rem;
  padding-bottom: 2rem;
}

.title {
  @include XL-C;
  color: $darkBlue;
}

.description {
  @include M-C;
  color: $darkBlue;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.logo {
  position: absolute;
  width: 9.5rem;
  height: 9.5rem;
  object-fit: contain;

  top: -2rem;
}

.buttonContainer {
  border-radius: 0.25rem;
  background-color: $lightBlue;
  padding: 1.2rem 2.8rem;
}

.buttonTitle {
  color: $white;
  @include M-C;
}
