@import 'src/Utils/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 1.125rem;
  margin-left: 1.5rem;
  flex-grow: 1;
}

.title {
  @include XL-L;
  color: black;
  margin-bottom: 2rem;
}

.separator {
  @extend %horizontal-line;
}

.resultList {
  height: 100%;
  margin-top: 1.125rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pageNavigator {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.noResult {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noResultImage {
  height: 12rem;
}

.noResultText {
  @include XL-L;
  padding-top: 2rem;
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
