@import 'src/Utils/variables.scss';

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 16.375rem;
}

.title {
  @include XL-L;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.detailSpacing {
  height: 1rem;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  width: 30.625rem;
  padding-left: 1rem;
}
