@import 'src/Utils/variables.scss';



.container {
  display: flex;
  flex-direction: column;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 2rem;
  background-color: $lightGrey;
  flex: 1;
}

.dropDown {
  background-color: white;
  width: 6rem;
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.offerPerPage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.subContainer {
  display: flex;
  flex-direction: row;
}

.title {
  @include XL-L;
  color: black;
}

.subTitle {
  @include L-L;
}

.exportButton {
@include XL-L;
}
