@import 'src/Utils/variables.scss';
@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  height: 5.625rem;
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
  border: 2px solid transparent;
}

.seenOffer {
  background-color: rgba(255, 255, 255, 0.4);
}

.left {
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 60%;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    @include S-C;
    margin-top: 0;
    margin-bottom: 0;
    color: $darkBlue;
  }
}

.seenRight {
  p {
    color: $main-navy-medium;
  }
}

.title {
  @include M-L;
  color: $lightBlue;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment_icon {
  padding-right: 0.5rem;
  height: 24px;
  width: 24px;
}

.seenTitle {
  color: $main-blue-medium;
}

.subtitle {
  @include M-L;
  color: $darkBlue;
}

.seenSubtitle {
  color: $main-navy-medium;
}

.button {
  @include icon-button;
}

/* On mouse-over, add a blue line color at the top and bottom of the container */

.container:hover {
  border: 2px solid $lightBlue;
}

.icon {
  color: $main-white-full;
}

.trigger {
  width: 2.5rem;
  min-width: 0;
  border-width: 0;
  height: 2.5rem;
  border-radius: 8px;
  padding: 0.5rem;
}

.activeTrigger {
  background-color: $contrast-grey-light;
  circle {
    fill: $main-navy-full;
  }
}
