@import 'src/Utils/variables.scss';
@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  width: 20.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem;
}

.switchRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.switchText {
  @include M-L;
  color: $main-blue-full;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.item {
  @include L-L;
  color: $lightBlue;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.selectedItem {
  color: $darkBlue !important;
}

.separator {
  @extend %separator;
}

.itemRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.sortIcon {
  max-height: 1rem;
  max-width: 1rem;
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  min-width: 0;
  border-width: 0;
  height: 2.5rem;
  border-radius: 8px;
  padding: 0.5rem;
}

.activeTrigger {
  background-color: $contrast-grey-light;
}
