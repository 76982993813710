@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.chip {
  background-color: transparent !important;
  border: 1px solid $main-blue-full !important;
  border-color: $main-blue-full !important;
  font-family: Graphik !important;
  font-size: 12px !important;
  color: $main-blue-full !important;
}

.chip > svg {
  fill: $main-blue-full;
}

.title {
  margin-top: 1.5rem;
}

.dropDownContainer {
  height: 11.5rem;
  overflow-y: scroll;
}

.dropdown {
  width: 100%;

  > div {
    text-overflow: ellipsis;
    min-width: calc(100% - 1.5rem);
    max-width: calc(100% - 1.5rem);
  }
}
