@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-beige-full;
  width: 100%;
  height: 100%;
  padding-left: 2.25rem;
  padding-top: 1.5rem;
  padding-right: 6.625rem;
}

.controlsLabel {
  @include Lnavyleft;
  font-size: 24px !important;
  line-height: 32px !important;
  letter-spacing: -1px !important;
}
